import BaseModel from "@/models/base-model.js";

export default class Supplier extends BaseModel {
  fields = {
    name: {
      type: "text",
      title: "Nama",
      label: "nama",
      minLen: 3,
      validationRules: {
        required: true,
        minLength: true,
      },
    },
    email: {
      type: "text",
      title: "Email",
      label: "email",
      minLen: 3,
      validationRules: {
        required: false,
        minLength: false,
        email: false,
      },
    },
    address: {
      type: "textarea",
      title: "Alamat",
      label: "address",
      minLen: 5,
      validationRules: {
        required: false,
        minLength: false,
      },
    },
    phone_number: {
      type: "phone",
      title: "No. HP",
      label: "phone_number",
      minLen: 11,
      validationRules: {
        required: false,
        minLength: false,
      },
    },
  };
  endpoint = process.env.VUE_APP_API_BASE_URL + "suppliers";
}
