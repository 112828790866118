<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import supplier from "@/models/supplier.js";
import detailGroupInput from "@/components/detail-group-input.vue";
import Loading from "@/components/skeleton.vue";

const supplierModel = new supplier();

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, detailGroupInput, Loading },
  page: {
    title: "Data Supplier",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Supplier",
          href: "/",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      isLoading: false,
      setData: [],
      columns: [
        {
          value: "name",
          label: "Nama",
        },
        {
          value: "email",
          label: "Email",
          textDefault: true,
        },
        {
          value: "phone_number",
          label: "No.Hp",
        },
        {
          value: "province.name",
          label: "Provinsi",
        },
        {
          value: "regency.name",
          label: "Kabupaten / Kota",
        },
        {
          value: "district.name",
          label: "Kecamatan",
        },
        {
          value: "village.name",
          label: "Desa",
        },
        {
          value: "updated_at",
          label: "Tanggal di Perbarui",
        },
        {
          value: "created_at",
          label: "Tanggal di Buat",
        },
        {
          value: "address",
          label: "Alamat"
        }
      ],
      rowCount: 9,
    };
  },
  watch: {
    "$route.params.id": function(val) {
      if (val !== undefined) {
        this.getDetail();
      }
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.isLoading = true;
      supplierModel
        .find(this.$route.params.id)
        .then((resp) => {
          // setModelValue(this.model, resp);
          this.setData = resp;
          this.isLoading = false;
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            console.error(error);
          }
          this.isLoading = false;
        });
    },
    returnBack() {
      return this.$router.go(-1);
      // return this.$router.push("/produk/warna");
    },
  },
  middleware: "authentication",
};
</script>
<template>
  <Layout>
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body>
        <!-- <div class="row d-flex justify-content-start m3">
          <b-btn
            variant="warning"
            @click="returnBack"
            class="ml-auto text-white"
            style="width: 4em;"
            ><i class="uil-arrow-left"></i
          ></b-btn>
        </div> -->
        <b-row v-if="!isLoading">
          <detail-group-input :columns="columns" :data="setData" />
        </b-row>
        <b-row v-else>
          <b-col class="my-3" sm="6" md="4" v-for="row in rowCount" :key="row">
            <loading
              class="my-2"
              width="50%"
              height="2rem"
              borderRadius="7px"
            />
            <loading width="100%" height="3rem" borderRadius="7px" />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </Layout>
</template>
